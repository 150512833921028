<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="联系客服"
                z-index="99"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div>
            <div class="contact-bg">
                <p class="contact_service_tit">
                    <img src="../../assets/images/contact_service_tit.png" alt="">
                    联系方式
                </p>
                <p class="contact-text">
                    您可以拨打客服联系电话，也可以联系客服微信QQ，即可在线沟通客服
                </p>
            </div>
            <div class="contact-div">
                <!-- <div class="contact-list">
                    <p class="contact_way1">
                        <img src="../../assets/images/contact_way1.png" alt="" class="contact_way1-img">
                        {{contactGetList.mobile}}
                    </p>
                    <p class="copy mobile" :data-clipboard-text="contactGetList.mobile" @click="mobile"
                       v-if="mobile_show">复制</p>
                    <p class="copy mobile_show" v-else>已复制</p>
                </div> -->
                <div class="contact-list">
                    <p class="contact_way1">
                        <img src="../../assets/images/contact_way2.png" alt="" class="contact_way2-img">
                        {{contactGetList.weixin}}
                    </p>
                    <p class="copy weixin" :data-clipboard-text="contactGetList.weixin" @click="weixin"
                       v-if="weixin_show">复制</p>
                    <p class="copy mobile_show" v-else>已复制</p>
                </div>
                <!-- <div class="contact-list">
                    <p class="contact_way1">
                        <img src="../../assets/images/contact_way3.png" alt="" class="contact_way3-img">
                        {{contactGetList.qq}}
                    </p>
                    <p class="copy qq" :data-clipboard-text="contactGetList.qq" @click="qq" v-if="qq_show">复制</p>
                    <p class="copy mobile_show" v-else>已复制</p>
                </div> -->
            </div>
            <p class="xian"></p>
            <p class="title">常见问题</p>
            <div class="title-list">
                <p :class="show =='1'?'active':''" @click="show='1'">{{name1}}</p>
                <p :class="show =='2'?'active':''" @click="show='2'">{{name2}}</p>
            </div>
            <template v-if="show=='1'">
                <van-collapse v-model="activeName" accordion :border="false">
                    <template v-for="(item,index) in contactGetList.common_problem">
                        <template v-if="item.class == name1">
                            <van-collapse-item :title="item.title" :name="index">
                                <div class="customer-text">
                                    {{item.explain}}
                                </div>
                            </van-collapse-item>
                        </template>
                    </template>
                </van-collapse>
            </template>
            <template v-if="show=='2'">
                <van-collapse v-model="activeName" accordion :border="false">
                    <template v-for="(item,index) in contactGetList.common_problem">
                        <template v-if="item.class == name2">
                            <van-collapse-item :title="item.title" :name="index">
                                <div class="customer-text">
                                    {{item.explain}}
                                </div>
                            </van-collapse-item>
                        </template>
                    </template>
                </van-collapse>
            </template>
        </div>
    </div>
</template>

<script>
    import Clipboard from 'clipboard';

    export default {
        name: "Customer",
        data() {
            return {
                show: '1',
                activeName: '0',
                contactGetList: [],
                name1: '',
                name2: '',
                mobile_show: true,
                weixin_show: true,
                qq_show: true,
            }
        },
        created() {
            this.contactGet()
        },
        methods: {
            mobile() {
                var clipboard = new Clipboard('.mobile')
                clipboard.on('success', e => {
                    this.$toast.success('复制成功');
                    this.mobile_show = false
                    this.weixin_show = true
                    this.qq_show = true
                    // 释放内存
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    this.$toast.fail('该浏览器或手机权限不支持复制功能');
                    // 释放内存
                    clipboard.destroy()
                })
            },
            weixin() {
                var clipboard = new Clipboard('.weixin')
                clipboard.on('success', e => {
                    this.$toast.success('复制成功');
                    this.mobile_show = true
                    this.weixin_show = false
                    this.qq_show = true
                    // 释放内存
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    this.$toast.fail('该浏览器或手机权限不支持复制功能');
                    // 释放内存
                    clipboard.destroy()
                })
            },
            qq() {
                var clipboard = new Clipboard('.qq')
                clipboard.on('success', e => {
                    this.$toast.success('复制成功');
                    this.mobile_show = true
                    this.weixin_show = true
                    this.qq_show = false
                    // 释放内存
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    this.$toast.fail('该浏览器或手机权限不支持复制功能');
                    // 释放内存
                    clipboard.destroy()
                })
            },

            contactGet() {
                this.$axios.get('/h5/contact')
                    .then(res => {
                        var res = res.data.data
                        this.contactGetList = res
                        this.name1 = this.contactGetList.common_problem[0].class
                        this.name2 = this.contactGetList.common_problem[1].class
                    })
            }
        }
    }
</script>

<style scoped>
    .mobile_show {
        background: #d4d3d3 !important;
    }

    .customer-text {
        background: #F2F5F8;
        padding: 40px;
        text-align: justify;
        border-radius: 10px;
    }

    .active {
        background: rgba(240, 243, 252, 1) !important;
        border: 1px solid rgba(77, 119, 253, 1) !important;
        color: #4D77FD !important;
    }

    .title-list > p {
        padding: 10px 30px;
        background: #F2F5F8;
        color: #909399;
        font-size: 28px;
        margin-right: 46px;
        border-radius: 4px;
    }

    .title-list {
        margin-bottom: 44px;
        display: flex;
        align-items: center;
        padding: 0 40px;
    }

    .title {
        color: #0A1C33;
        font-size: 32px;
        padding: 40px;
    }

    .xian {
        height: 20px;
        background: #F2F5F8;
    }

    .contact_way3-img {
        width: 28px;
        height: 32px;
    }

    .contact_way2-img {
        width: 33px;
        height: 27px;
    }

    .copy {
        width: 144px;
        height: 60px;
        background: rgba(77, 119, 253, 1);
        border-radius: 30px;
        font-size: 28px;
        color: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .contact_way1-img {
        width: 26px;
        height: 28px;
    }

    .contact_way1 {
        font-size: 32px;
        color: #0A1C33;
        display: flex;
        align-items: center;
    }

    .contact_way1 > img {
        margin-right: 15px;
    }

    .contact-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
    }

    .contact-list:last-of-type {
        margin-bottom: 0 !important;
    }

    .contact-div {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 46px 0px rgba(132, 134, 156, 0.1);
        border-radius: 20px;
        padding: 40px;
        margin: 0 40px;
        position: relative;
        top: -40px;
    }

    .contact-text {
        color: #fff;
        font-size: 28px;
        margin-top: 30px;
        line-height: 40px;
        text-align: justify;
    }

    .contact_service_tit {
        display: flex;
        align-items: center;
        font-size: 30px;
        color: #fff;
    }

    .contact_service_tit > img {
        width: 32px;
        height: 32px;
        margin-right: 10px;
    }

    .contact-bg {
        background: url("../../assets/images/contact_service_bgx.png") no-repeat;
        background-size: cover;
        height: 250px;
        padding: 40px;
    }
</style>